const settings = {
  api_url: '/api',
  socket_url: '/',      // socket url; any sub dir may not work! eg /api will not work
  languages: ['pl', 'en'],              // All available languages

  normalAdminModules: ['content_packs', 'logs', 'maps', 'map_objects', 'mapobjects', 'semesters', 'challenge_categories', 'challenges'],

//  custom_map_colors: ['#cf4e50'],
	  custom_map_colors: ['#ff0000', '#000000', '#EAE8FF', '#ffd700', '#0057b7', '#067eea'],


   login_only_openid: false,


   /*custom_icon_set: {
    bp_1_white: {
      shape: 'image',
      image: '/images/customization/icons/bp/1_white.svg',
      size: 32
    },
    bp_2_white: {
      shape: 'image',
      image: '/images/customization/icons/bp/2_white.svg',
      size: 32
    },

    bp_3_yellow: {
      shape: 'image',
      image: '/images/customization/icons/bp/3_yellow.svg',
      size: 32
    },
    bp_4_yellow: {
      shape: 'image',
      image: '/images/customization/icons/bp/4_yellow.svg',
      size: 32
    },

    bp_5_orange: {
      shape: 'image',
      image: '/images/customization/icons/bp/5_orange.svg',
      size: 32
    },
    bp_6_orange: {
      shape: 'image',
      image: '/images/customization/icons/bp/6_orange.svg',
      size: 32
    },

    bp_7_green: {
      shape: 'image',
      image: '/images/customization/icons/bp/7_green.svg',
      size: 32
    },
    bp_8_green: {
      shape: 'image',
      image: '/images/customization/icons/bp/8_green.svg',
      size: 32
    },

    bp_9_blue: {
      shape: 'image',
      image: '/images/customization/icons/bp/9_blue.svg',
      size: 32
    },
    bp_10_blue: {
      shape: 'image',
      image: '/images/customization/icons/bp/10_blue.svg',
      size: 32
    },

    bp_11_purple: {
      shape: 'image',
      image: '/images/customization/icons/bp/11_purple.svg',
      size: 32
    },
    bp_12_purple: {
      shape: 'image',
      image: '/images/customization/icons/bp/12_purple.svg',
      size: 32
    },

    bp_13_red: {
      shape: 'image',
      image: '/images/customization/icons/bp/13_red.svg',
      size: 32
    },
    bp_14_red: {
      shape: 'image',
      image: '/images/customization/icons/bp/14_red.svg',
      size: 32
    },

    bp_15_brown: {
      shape: 'image',
      image: '/images/customization/icons/bp/15_brown.svg',
      size: 32
    },
    bp_16_brown: {
      shape: 'image',
      image: '/images/customization/icons/bp/16_brown.svg',
      size: 32
    },

    bp_17_gray: {
      shape: 'image',
      image: '/images/customization/icons/bp/17_gray.svg',
      size: 32
    },
    bp_18_gray: {
      shape: 'image',
      image: '/images/customization/icons/bp/18_gray.svg',
      size: 32
    },

    bp_19_black: {
      shape: 'image',
      image: '/images/customization/icons/bp/19_black.svg',
      size: 32
    },
    bp_20_black: {
      shape: 'image',
      image: '/images/customization/icons/bp/20_black.svg',
      size: 32
    },

    bp_belt_black: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_black.svg',
      size: 32
    },
    bp_belt_black_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_black_2.svg',
      size: 32
    },

    bp_belt_blue: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_blue.svg',
      size: 32
    },
    bp_belt_blue_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_blue_2.svg',
      size: 32
    },

    bp_belt_brown: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_brown.svg',
      size: 32
    },
    bp_belt_brown_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_brown_2.svg',
      size: 32
    },

    bp_belt_gray: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_gray.svg',
      size: 32
    },
    bp_belt_gray_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_gray_2.svg',
      size: 32
    },

    bp_belt_green: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_green.svg',
      size: 32
    },
    bp_belt_green_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_green_2.svg',
      size: 32
    },

    bp_belt_orange: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_orange.svg',
      size: 32
    },
    bp_belt_orange_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_orange_2.svg',
      size: 32
    },

    bp_belt_purple: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_purple.svg',
      size: 32
    },
    bp_belt_purple_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_purple_2.svg',
      size: 32
    },

    bp_belt_red: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_red.svg',
      size: 32
    },
    bp_belt_red_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_red_2.svg',
      size: 32
    },

    bp_belt_white: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_white.svg',
      size: 32
    },
    bp_belt_white_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_white_2.svg',
      size: 32
    },

    bp_belt_yellow: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_yellow.svg',
      size: 32
    },
    bp_belt_yellow_2: {
      shape: 'image',
      image: '/images/customization/icons/bp/belt_yellow_2.svg',
      size: 32
    },

    bp_13_black: {
      shape: 'image',
      image: '/images/customization/icons/bp/13_black.svg',
      size: 32
    },
    bp_14_black: {
      shape: 'image',
      image: '/images/customization/icons/bp/14_black.svg',
      size: 32
    },

    learn: {
      shape: 'image',
      image: '/images/customization/icons/bp/learn.svg',
      size: 32
    },
    pratice: {
      shape: 'image',
      image: '/images/customization/icons/bp/practice.svg',
      size: 32
    },


    crypto: {
      shape: 'image',
      image: '/images/customization/icons/set2/crypto.svg',
      size: 32
    },
    linux_exploitation: {
      shape: 'image',
      image: '/images/customization/icons/set2/linux_exploitation.svg',
      size: 32
    },
    misc1: {
      shape: 'image',
      image: '/images/customization/icons/set2/misc1.svg',
      size: 32
    },
    misc2: {
      shape: 'image',
      image: '/images/customization/icons/set2/misc2.svg',
      size: 32
    },
    pwn: {
      shape: 'image',
      image: '/images/customization/icons/set2/pwn.svg',
      size: 32
    },
    reverse: {
      shape: 'image',
      image: '/images/customization/icons/set2/reverse.svg',
      size: 32
    },
    stegano: {
      shape: 'image',
      image: '/images/customization/icons/set2/stegano.svg',
      size: 32
    },
    web: {
      shape: 'image',
      image: '/images/customization/icons/set2/web.svg',
      size: 32
    },
    windows_exploitation: {
      shape: 'image',
      image: '/images/customization/icons/set2/windows_exploitation.svg',
      size: 32
    },
    cve: {
      shape: 'image',
      image: '/images/customization/icons/set2/cve.svg',
      size: 32
    },
    network: {
      shape: 'image',
      image: '/images/customization/icons/set2/network.svg',
      size: 32
    },
    pentest: {
      shape: 'image',
      image: '/images/customization/icons/set2/pentest.svg',
      size: 32
    },
    pwn2: {
      shape: 'image',
      image: '/images/customization/icons/set2/pwn2.svg',
      size: 32
    },
  },
  */

  moodle_oauth_enabled: true,               // is Moodle oauth enabled
  moodle_url: 'http://10.77.9.152/moodle',  // full url to Moodle installation
  moodle_client_id: 'hd',                   // "Client identifier" configured in OAuth provider



  // app_title: 'Custom CTF Name',              // Custom title tag value to set
  // app_footer: 'Custom Footer Info ©2023',    // Custom value inside app footer
  // app_login_text: 'Custom Login Text',       // Custom value used on login screen
  // app_logo: 'logo_light_small_changed.png',  // Custom logo to use on login, register and password reset screens; filename from public/images/customization directory
  // app_logo_menu: 'logo_light_changed.png',   // Custom logo to use in menu; filename from public/images/customization directory
  // app_loader: 'loader_changed.svg',          // Custom logo to use in app loader; filename from public/images/customization directory
  // app_favicon: 'favicon_changed.png',        // Custom favicon to use; filename from public/images/customization directory
  default_lang: 'pl',                   // Default app language
  auth_handling: true,
  installation_type: 'cloud',           // installation type: [cloud, box]
  recruitment: false,                   // true if this is install for recruitment purposes
  ajax_refresh_rate: 0,                 // Wyrażony w sekundach, odstęp czasu pomiędzy pobieraniem danych widoku maszyn i scenariuszy
  registration: false,                  // Is registration form enabled
  password_reset: false,                // Is password reset form enabled
  instruction: false,                   // Show instruction button in top nav
  // stack_switch_state: 'poweroff',    // Action to make on previous stack when switching stack, if not set no prompt will show
  // stack_switch_default_change_state: false, // Make stack switch prompt state change checkbox checked for default; if false or not set will be not checked
  scenarios_blocking_enabled: true,    // Are scenario reservations enabled?
  overlord_enabled: true,
  stack_types: [                        // Stack types available for this install
    'box', 'cloud', 'recruitment', 'ctf_lab', 'ctf', 'beginners_path', 'malware_lab'
  ],
  ca_certificates: [
    'hackingdept', 'hackgroup'
  ],
  demo_types: [
    'full', 'partial'
  ],
  roles: [                              // Roles available for set for users
    {'role': 1, 'name': 'admin'},
    {'role': 2, 'name': 'user'},
    {'role': 3, 'name': 'user_recruitment'},
    {'role': 4, 'name': 'user_ctf_ro'}
  ],
  networks: [                           // VPN Networks available for this install
    // {'network_name': 'hackgroup-dmz', 'title': 'HackGroup DMZ'},
    {'network_name': 'hackbank-lan', 'title': 'HackBank LAN'},
    // {'network_name': 'hackgroup-malwarelab', 'title': 'HackGroup MalwareLab'},
    // {'network_name': 'hackstock-lan', 'title': 'HackStock LAN'},
    // {'network_name': 'ctf-lab-network', 'title': 'CTF Lab Network'},
    {'network_name': 'attackers-network', 'title': 'Attacker\'s Network'},
    {'network_name': 'management-network', 'title': 'HD Management Network'},
    {'network_name': 'pentesters-network', 'title': 'Pentester\'s Network'},
    {'network_name': 'ctf', 'title': 'CTF'},
  ]
}



const views = {
  sa:"fNOvREpuGbsjgG5EXYfFA7J0Ebb2G0Ua",
  ad:"RkGXhJ3L0XrqTd9JSGVtvdRZJ99A6isu",
  us:"iZeephooGaiquo3Quiesh0ooLoh5xeij",
  re:"be8oe6cae9Kaequ5Gahsiexoobeequey",
  ucr:"IFoiTAfaitANxvGy58Pb5J1iwoXmqfEH"
}

export default settings;
export { views }
